import { getISONow } from "../utility/dateConversion";

import User from "../Classes/User";
import { SPECS } from "../Constants/Message";
import {
  MANUFACTURER_MODEL_NAME,
  SKINAID_APP_NAME,
} from "../Constants/Constants";

const DIVIDER = 3.0;

/**
 * Create DICOM body for new record
 * @returns
 */
export const createGenerateDICOMBody = (
  numOfFrames,
  physicalDeltaY,
  version
) => ({
  ManufacturerModelName: MANUFACTURER_MODEL_NAME.replace(" ", "_"),
  SoftwareVersions: `${SKINAID_APP_NAME.replace(" ", "")}_v${version}`,
  DeviceSerialNumber: "",
  PhysicalDeltaY: physicalDeltaY,
  NumberOfFrames: numOfFrames ?? 1,
});

/**
 * Process note: plain text to array
 * @param {String} note noteTmp - string
 * @returns {Array<Object>} tmpArray - object array of selected note tags (label-value pairs)
 */
export const notePlainToArray = (note) => {
  let noteValues = note.split(";");
  const tmpArray = [];
  for (let i = 0; i < noteValues.length; i++) {
    tmpArray.push({
      value: noteValues[i].trim(),
      label: noteValues[i].trim(),
    });
  }
  return tmpArray;
};

/**
 * Process note: array to plain text
 * @param {Array<Object>} selectedOption - object array of selected note tags (label-value pairs)
 * @returns {String} noteTmp - string
 */
export const noteToPlainText = (selectedOption) => {
  let noteTmp = "";
  for (let i = 0; i < selectedOption.length; i++) {
    noteTmp += selectedOption[i].value;
    i < selectedOption.length - 1 ? (noteTmp += "; ") : (noteTmp += "");
  }
  return noteTmp;
};

/**
 * Restructure raw user
 * @param {Object} data raw user
 * @returns {User} user
 */
export const processUser = (data) => {
  if (data.isCompany) {
    data.isCompany = data.isCompany.localeCompare("0") === 0 ? false : true;
  }
  if (data.specialization) {
    let spec = SPECS.find(
      (item) =>
        item.value
          .toLowerCase()
          .localeCompare(data.specialization.toLowerCase()) === 0
    );
    if (!spec) {
      spec = SPECS.find(
        (item) =>
          item.displayValue
            .toLowerCase()
            .localeCompare(data.specialization.toLowerCase()) === 0
      );
    }
    data.specialization = spec;
  }

  return new User(
    data.name,
    data.affiliation,
    data.specialization,
    data.mprn,
    data.email,
    data.isCompany,
    data.address,
    data.company_name,
    data.company_vat
  );
};

/**
 * Process optofuse
 * @param {Object} data optofuse
 * @returns {Object} {maskKey: string, maskUrl: string, depthKey: string, depthUrl: string}
 */
export const processOptoFuse = (data) => {
  return {
    maskKey: data.mask_key,
    maskUrl: data.mask_url,
    depthKey: data.depth_key,
    depthUrl: data.depth_url,
    registration: data.registration,
    prevGen: data.prev_gen,
  };
};

/**
 * Create add note body
 * @param {String} note note
 */
export const createAddNoteBody = (note) => ({ date: getISONow(), note });
/**
 * Create addand modify patient body
 * @param {String} birthYear
 * @param {String} sex
 * @param {String} note
 */
export const createAddModifyPatientBody = (birthYear, sex, note) => ({
  birth_year: birthYear,
  sex:
    sex === "female"
      ? "female"
      : sex === "male"
      ? "male"
      : sex === "other"
      ? "Other/declined to give"
      : "unknown",
  note,
});

/**
 * Create add lesion body
 * @param {String} patientId
 * @param {*} lesionLocation
 * @param {String} note
 */
export const createAddLesionBody = (patientId, lesionLocation, note) => {
  const multiplier = lesionLocation.index === 0 ? 1 : -1;
  return {
    patient_id: patientId,
    coord_x: Math.round(multiplier * lesionLocation.x * 742 * DIVIDER),
    coord_y: Math.round(multiplier * lesionLocation.y * 1048 * DIVIDER),
    note,
  };
};

/**
 * Create sign up body
 */
export const createSignUpBody = (
  name,
  affiliation,
  specialization,
  mprn,
  email,
  password,
  isCompany,
  company_name,
  companyAddress,
  company_vat,
  address
) =>
  isCompany
    ? {
        name,
        affiliation,
        specialization,
        mprn,
        email,
        password,
        isCompany: isCompany ? 1 : 0,
        company_name: company_name,
        company_vat: company_vat,
        address: companyAddress,
      }
    : {
        name,
        affiliation,
        specialization,
        mprn,
        email,
        password,
        isCompany: isCompany ? 1 : 0,
        address,
      };
/**
 * Create user modification body
 */
export const createUserModifyBody = (
  name,
  affiliation,
  specialization,
  mprn,
  isCompany,
  company_name,
  company_vat,
  companyAddress,
  address
) => {
  const body = {};
  if (name) {
    body.name = name;
  }
  if (affiliation) {
    body.affiliation = affiliation;
  }
  if (specialization) {
    body.specialization = specialization;
  }
  if (mprn) {
    body.mprn = mprn;
  }
  body.isCompany = isCompany ? "1" : "0";
  if (isCompany) {
    if (company_name) {
      body.company_name = company_name;
    }
    if (company_vat) {
      body.company_vat = company_vat;
    }
    if (address) {
      body.address = companyAddress;
    }
  } else {
    body.company_name = "";
    body.company_vat = "";
    if (address) {
      body.address = address;
    }
  }
  return body;
};

/**
 * Create change psw body
 */
export const createChangePswBody = (previous_password, proposed_password) => ({
  previous_password,
  proposed_password,
});

/**
 * Create forgot psw body
 */
export const createForgotPswBody = (email) => ({
  email,
});

/**
 * Create forgot psw body
 */
export const createConfirmPswBody = (email, code, newPsw) => ({
  email,
  code,
  newPsw,
});

/**
 * Create change email body
 */
export const createChangeEmailBody = (email) => ({ email });

/**
 * Create verificaiton email body
 */
export const createVerifyEmailBody = (verification_code) => ({
  verification_code,
});

/**
 * Create save segmentation body
 * @param {String} note note
 */
export const createSaveSegmentationBody = (polygons) => {
  const keys = Object.keys(polygons);
  const body = {};
  keys.forEach((item) => {
    body[item] = JSON.stringify(polygons[item]);
  });

  return body;
};

import { useState } from "react";
import PropTypes from "prop-types";

import styles from "./Layout.module.css";
import Toolbar from "../Navigation/Toolbar/Toolbar";
import SideDrawer from "../Navigation/SideDrawer/SideDrawer";
import ErrorBoundary from "./ErrorBoundary";
import useWindowSize from "../../hooks/useWindowSize";

const MOBILE_MODE = 500;
export const isMobilMode = (width) => width <= MOBILE_MODE;

const Layout = (props) => {
  const [showSideDrawer, setShowSideDrawer] = useState(false);
  const size = useWindowSize();

  return (
    <ErrorBoundary>
      <Toolbar
        appVersion={props.appVersion}
        isAuthenticated={props.isAuthenticated}
        onDrawerToggleClick={() => setShowSideDrawer(true)}
        show={!isMobilMode(size.width)}
      >
        {props.navItems}
      </Toolbar>
      <SideDrawer
        appVersion={props.appVersion}
        isAuthenticated={props.isAuthenticated}
        isOpen={showSideDrawer}
        hide={!isMobilMode(size.width)}
        onClose={() => setShowSideDrawer(false)}
      >
        {props.navItems}
      </SideDrawer>
      <main className={styles.Content}>{props.children}</main>
    </ErrorBoundary>
  );
};

Layout.propTypes = {
  appVersion: PropTypes.string,
  isAuthenticated: PropTypes.bool.isRequired,
  navItems: PropTypes.node,
};
export default Layout;

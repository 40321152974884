import { useReducer, useCallback } from "react";
import dermusAxios from "./dermusAxios";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import axios from "axios";
import qs from "qs";

import Action from "../Classes/Store/Action";
import { checkUserValidity } from "../API/authenticationAPI";
import { setStaleActionCreator } from "../store/actionCreators/authenticationActionCreator";

export const GET = "GET";
export const POST = "POST";
export const PUT = "PUT";
export const DELETE = "DELETE";

const SEND = "SEND";
const CLEAR = "CLEAR";
const RESPONSE = "RESPONSE";
const ERROR = "ERROR";

const initialState = {
  pending: false,
  error: null,
  data: null,
  extra: null,
  identifier: null,
};

/**
 * Reducer fot the http state
 * @param {*} curHttpState
 * @param {Action} action
 */
const httpReducer = (curHttpState, action) => {
  switch (action.type) {
    case SEND:
      return {
        pending: true,
        error: null,
        data: null,
      };
    case RESPONSE:
      return {
        ...curHttpState,
        pending: false,
        data: action.payload.responseData,
      };
    case ERROR:
      return { pending: false, error: action.payload.errorMessage };
    case CLEAR:
    default:
      return { ...initialState };
  }
};
//Cancel token
const CancelToken = axios.CancelToken;

const useHttp = (axiosInterface) => {
  //Axios interface
  const [axisoIf] = useState(() => axiosInterface ?? dermusAxios);
  //State
  const [httpState, dispatchHttp] = useReducer(httpReducer, initialState);
  //Session of auth
  const session = useSelector((state) => state.auth.session);
  //Clear state
  const clear = useCallback(() => dispatchHttp(new Action(CLEAR)), []);
  //Source of cacnel token
  const [source] = useState(CancelToken.source());
  //State of cancel request
  const [canceled, setCanceled] = useState(false);
  //Redux dispatch
  const dispatch = useDispatch();
  //Cacnel request
  const cancel = useCallback(() => {
    setCanceled(true);
    source.cancel();
  }, [source]);

  /**
   * Reducer fot the http state
   * @param {String} endpoint endpoint where send request
   * @param {String} method request method
   * @param {Function} postProcess callback funciton, call on succes data
   * @param {Object} body object to send
   * @param {Object} headerExtra append header with this
   */
  const sendRequestWithoutCheck = useCallback(
    (endpoint, method, postProcess, body, headerExtra) => {
      dispatchHttp(new Action(SEND));
      let promise;
      switch (method) {
        case POST:
          promise = axisoIf.post(endpoint, qs.stringify(body), {
            headers: {
              "content-type": "application/x-www-form-urlencoded;charset=utf-8",
              Authorization: session.getIdToken().jwtToken,
              ...headerExtra,
            },
            cancelToken: source.token,
          });
          break;
        case PUT:
          promise = axisoIf.put(endpoint, qs.stringify(body), {
            headers: {
              "content-type": "application/x-www-form-urlencoded;charset=utf-8",
              Authorization: session.getIdToken().jwtToken,
              ...headerExtra,
            },
            cancelToken: source.token,
          });
          break;
        case DELETE:
          promise = axisoIf.delete(endpoint, {
            headers: {
              "content-type": "application/x-www-form-urlencoded;charset=utf-8",
              Authorization: session.getIdToken().jwtToken,
              ...headerExtra,
            },
            cancelToken: source.token,
          });
          break;
        case GET:
          promise = axisoIf.get(endpoint, {
            headers: {
              Authorization: session.getIdToken().jwtToken,
              "Content-Type": "application/json",
              ...headerExtra,
            },
            cancelToken: source.token,
          });
          break;
        default:
          promise = null;
      }
      if (promise) {
        promise
          .then((response) => {
            if (response.staleCache) {
              dispatch(setStaleActionCreator(true));
            }
            let processedData = response.data;
            if (postProcess) {
              processedData = postProcess(response.data);
            }

            if (!canceled) {
              dispatchHttp(
                new Action(RESPONSE, { responseData: processedData })
              );
            }
          })
          .catch((e) => {
            if (!axios.isCancel(e)) {
              if (e.response && e.response.data) {
                dispatchHttp(
                  new Action(ERROR, { errorMessage: e.response.data.message })
                );
                return;
              }
              dispatchHttp(new Action(ERROR, { errorMessage: e.message }));
            }
          });
      }
    },
    [session, source, canceled, dispatch, axisoIf]
  );
  /**
   * Reducer fot the http state
   * @param {String} endpoint endpoint where send request
   * @param {String} method request method
   * @param {Boolean} resnedOnRefres try resend of seesion expired
   * @param {Function} postProcess callback funciton, call on succes data
   * @param {Object} body object to send
   * @param {Object} headerExtra apeend header with this
   */
  const sendRequest = useCallback(
    (endpoint, method, resnedOnRefres, postProcess, body, headerExtra) => {
      if (session.isValid()) {
        sendRequestWithoutCheck(
          endpoint,
          method,
          postProcess,
          body,
          headerExtra
        );
      } else {
        checkUserValidity(
          dispatch,
          resnedOnRefres
            ? () =>
                sendRequestWithoutCheck(
                  endpoint,
                  method,
                  postProcess,
                  body,
                  headerExtra
                )
            : null
        );
      }
    },
    [sendRequestWithoutCheck, dispatch, session]
  );

  return [
    httpState.data,
    sendRequest,
    cancel,
    httpState.pending,
    httpState.error,
    clear,
    httpState.extra,
    httpState.identifier,
  ];
};

export default useHttp;

import { memo, Fragment } from "react";
import PropTypes from "prop-types";

import styles from "./GeneralModal.module.css";

import { OK, CANCEL } from "../Constants";
import Spinner from "../Spinner/Spinner";
import Backdrop from "../Backdrop/Backdrop";
import Button from "../Button/Button";
import TitleWithBackground from "../Divs/TitleWithBackground/TitleWithBackground";
import SizeChangedText from "../Divs/SizeChangedText/SizeChangedText";

/**
 * General Modal
 * @param {Object} props {show: shor or not (boolean), onClose: on close function (Function), title: title (String), pending: pending or not (boolean), onCancel: on cancel  function (Function), onConfirm: on confirm  function (Function), okTitle: ok title (String)}
 */
const GeneralModal = memo((props) => {
  return props.show !== false ? (
    <Fragment>
      <Backdrop onClick={props.onClose} show />
      <div className={styles.InputModal}>
        <TitleWithBackground title={props.title} />
        {props.pending ? (
          <Spinner />
        ) : (
          <Fragment>
            <SizeChangedText>{props.children}</SizeChangedText>
            <div className={styles.InputModal__actions}>
              {props.onConfirm ? (
                <Button
                  onClick={props.onConfirm}
                  title={props.okTitle ? props.okTitle : OK}
                />
              ) : null}
              {props.onCancel ? (
                <Button onClick={props.onCancel} title={CANCEL} />
              ) : null}
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  ) : null;
});
GeneralModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  pending: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  okTitle: PropTypes.string,
  title: PropTypes.string,
};
export default GeneralModal;

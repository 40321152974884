import PropTypes from "prop-types";

import styles from "./Container.module.css";
/**
 * Container
 * @param {Object} props {title: string (title of form), children: children (inside the form)}
 */
const Container = (props) => {
  return (
    <div className={styles.Container}>
      <div className={styles.Title}>{props.title}</div>
      {props.children}
    </div>
  );
};
Container.propTypes = {
  title: PropTypes.string,
};
export default Container;

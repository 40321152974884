import PropTypes from "prop-types";
import styles from "./Snackbar.module.css";

const Snackbar = (props) => {
  return (
    <div className={props.show ? styles.SnackbarShow : styles.Snackbar}>
      {props.msg}
    </div>
  );
};

Snackbar.propTypes = { show: PropTypes.bool, msg: PropTypes.string };
export default Snackbar;

import PropTypes from "prop-types";

import styles from "./AppNameWithVersion.module.css";

/**
 * SkinAidWithVersion: props (appName:  name of the app, info: extra info e.g.: TESTING ONLY)
 */
const AppNameWithVersion = (props) => (
  <p className={styles.SkinAidWithVersion}>
    {props.appName +
      " v" +
      props.appVersion +
      (props.info ? " — " + props.info : "")}
  </p>
);
AppNameWithVersion.propTypes = {
  appName: PropTypes.string.isRequired,
  info: PropTypes.string,
};

export default AppNameWithVersion;

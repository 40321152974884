import { Fragment } from "react";
import PropTypes from "prop-types";

import styles from "./Toolbar.module.css";
import Logo from "../../Logo/Logo";
import DrawerToggle from "../SideDrawer/DrawerToggle/DrawerToggle";
import AppNameWithVersion from "../AppNameWithVersion";
import { SKINAID_APP_NAME } from "../../../Constants/Constants";
import { ADMIN } from "../../../Constants/Message";

/**
 * Toolbar
 * @param {Object} props  {onDrawerToggleClick - on click to drawer toggle (function), isAuthenticated - auth or not (boolean), show: show menu(boolean)}
 */
const toolbar = (props) => (
  <header className={styles.Toolbar}>
    <DrawerToggle onClick={props.onDrawerToggleClick} />
    <div className={styles.Logo}>
      <Logo />
    </div>
    {props.show ? (
      <Fragment>
        <AppNameWithVersion
          appName={SKINAID_APP_NAME}
          info={ADMIN}
          appVersion={props.appVersion}
        />
        <nav>{props.children}</nav>
      </Fragment>
    ) : null}
  </header>
);

toolbar.propTypes = {
  onDrawerToggleClick: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,

  appVersion: PropTypes.string,
};

export default toolbar;

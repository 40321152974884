import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";

import * as action from "../store/actionCreators/authenticationActionCreator";
import Pool from "../UserPool";

import { LOGOUT_EP, SIGN_UP_EP, LOGIN_EP, FORGOT_PSW_EP } from "./endPoints";
import qs from "qs";

import jwtDecode from "jwt-decode";

import {
  ADD_VALID_SPEC,
  ADD_VALID_USER_NAME,
  ADD_VALID_AFF,
  ADD_VALID_PSW,
  ADD_VALID_EMAIL,
  EMAILS_NOT_SAME,
  PSWS_NOT_SAME,
  ADD_VALID_VERIFICATION_CODE,
  CHOOSE,
  NOT_AGREE_TERMS_AND_CONDITION,
  NOT_AGREE_PRIVACY_POLICY,
  ADD_VALID_COMPANY_NAME,
  ADD_VALID_COMPANY_ADDRESS,
  ADD_VALID_COMPANY_VAT,
  ADD_VALID_ADDRESS,
  NO_ADMIN_RIGHTS,
} from "../Constants/Message";

import { clearStorage, storeItem } from "./localStorageAPI";
import { USER_SIGNED_IN_LOCAL_STORAGE } from "../Constants/Constants";
import dermusAxios from "./dermusAxios";
import {
  createSignUpBody,
  createForgotPswBody,
  createConfirmPswBody,
} from "./dataRestructure";

const checkPermission = (token, permission) => {
  // checking if user belongs to admin group or not
  const groups = jwtDecode(token)["cognito:groups"];
  if (groups && groups.includes(permission)) {
    return true;
  } else {
    return false;
  }
};
/**
 * Get user session
 * @param {Function} dispatch - dispatch to dispatch to redux
 * @param {Function} callback - callback on valid session
 */
export const checkUserValidity = (dispatch, callback) => {
  const user = Pool.getCurrentUser();
  if (user) {
    user.getSession((err, session) => {
      // checking if user belongs to admin group or not
      if (!checkPermission(session.getIdToken().jwtToken, "admin")) {
        dispatch(action.loginFailedActionCreator({ message: NO_ADMIN_RIGHTS }));
        return;
      }

      if (err) {
        dispatch(action.getSessionErrorActionCreator());
        logout(dispatch, session);
      } else {
        if (session.isValid()) {
          dispatch(action.setSessionActionCreator(session));
          if (callback) {
            callback();
          }
        } else {
          logout(dispatch, session);
        }
      }
    });
  } else {
    dispatch(action.noUserActionCreator());
  }
};
/**
 * Login
 * @param {Object} username - username (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} password - password (object: valid - boolean, touched - boolean, value: string)
 * @param {Function} dispatch - dispatch to dispatch to redux
 */
export const login = (username, password, dispatch) => {
  if (!username.valid || !username.touched) {
    dispatch(action.loginFailedActionCreator({ message: ADD_VALID_EMAIL }));
    return;
  }
  if (!password.valid || !password.touched) {
    dispatch(action.loginFailedActionCreator({ message: ADD_VALID_PSW }));
    return;
  }

  if (dispatch) {
    dispatch(action.loginStartActionCreator());
  }
  const user = new CognitoUser({ Username: username.value, Pool: Pool });
  const authDetails = new AuthenticationDetails({
    Username: username.value,
    Password: password.value,
  });

  user.authenticateUser(authDetails, {
    onSuccess: (session) => {
      // checking if user belongs to admin group or not
      if (!checkPermission(session.getIdToken().jwtToken, "admin")) {
        dispatch(action.loginFailedActionCreator({ message: NO_ADMIN_RIGHTS }));
        return;
      }

      dermusAxios.put(
        //logging
        LOGIN_EP,
        {},
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
            Authorization: session.getIdToken().jwtToken,
          },
        }
      );
      storeItem(USER_SIGNED_IN_LOCAL_STORAGE, username.value);
      if (dispatch) {
        dispatch(
          action.loginSuccessActionCreator(
            session,
            password.value,
            username.value
          )
        );
      }
    },

    onFailure: (err) => {
      clearStorage();
      if (dispatch) {
        dispatch(action.loginFailedActionCreator(err));
      }
    },
  });
};

/**
 * Logout user
 * @param {Function} dispatch - dispatch to dispatch to redux
 * @param {CognitoUserSession} session - cognito session
 */
export const logout = (dispatch, session) => {
  if (dispatch) {
    dispatch(action.logoutActionCreator());
  }
  const user = Pool.getCurrentUser();
  if (user) {
    dermusAxios.put(
      //logging
      LOGOUT_EP,
      {},
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          Authorization: session.getIdToken().jwtToken,
        },
      }
    );
    user.signOut();
  }
  clearStorage();
};

/**
 * Sign up new user
 * @param {Object} name - name (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} affiliation - affiliation (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} spec - spec (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} mprn - medical practitioner reg num (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} email - email (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} emailConf - emailConf (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} psw - psw (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} pswConf - pswConf (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} isCompany - medical practitioner reg num (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} companyName - company name(object: valid - boolean, touched - boolean, value: string)
 * @param {Object} companyAddress - company address (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} companyVAT - company vat num (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} address - user adrres (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} isTermsAndCond - muser agreement agree (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} isPrivacyPolicy - data policy agree (object: valid - boolean, touched - boolean, value: string)
 * @param {Function} dispatch - dispatch to dispatch to redux
 */
export const signupValidation = (
  name,
  affiliation,
  spec,
  mprn,
  email,
  emailConf,
  psw,
  pswConf,
  isCompany,
  companyName,
  companyAddress,
  companyVAT,
  address,
  isTermsAndCond,
  isPrivacyPolicy,
  dispatch
) => {
  if (!name.valid || !name.touched) {
    dispatch(
      action.signupFailedActionCreator({ message: ADD_VALID_USER_NAME })
    );
    return;
  }
  if (!affiliation.valid || !affiliation.touched) {
    dispatch(action.signupFailedActionCreator({ message: ADD_VALID_AFF }));
    return false;
  }
  if (!spec.valid || !spec.touched || spec.value === CHOOSE) {
    dispatch(action.signupFailedActionCreator({ message: ADD_VALID_SPEC }));
    return false;
  }
  if (!email.valid || !email.touched) {
    dispatch(action.signupFailedActionCreator({ message: ADD_VALID_EMAIL }));
    return false;
  }
  if (email.value !== emailConf.value) {
    dispatch(action.signupFailedActionCreator({ message: EMAILS_NOT_SAME }));
    return false;
  }
  if (!psw.valid || !psw.touched) {
    dispatch(action.signupFailedActionCreator({ message: ADD_VALID_PSW }));
    return false;
  }
  if (psw.value !== pswConf.value) {
    dispatch(action.signupFailedActionCreator({ message: PSWS_NOT_SAME }));
    return false;
  }

  if (isCompany.value) {
    if (!companyName.valid || !companyName.touched) {
      dispatch(
        action.signupFailedActionCreator({ message: ADD_VALID_COMPANY_NAME })
      );
      return false;
    }
    if (!companyAddress.valid || !companyAddress.touched) {
      dispatch(
        action.signupFailedActionCreator({ message: ADD_VALID_COMPANY_ADDRESS })
      );
      return false;
    }
    if (!companyVAT.valid || !companyVAT.touched) {
      dispatch(
        action.signupFailedActionCreator({ message: ADD_VALID_COMPANY_VAT })
      );
      return false;
    }
  } else {
    if (!address.valid || !address.touched) {
      dispatch(
        action.signupFailedActionCreator({ message: ADD_VALID_ADDRESS })
      );
      return false;
    }
  }

  if (!isTermsAndCond.value) {
    dispatch(
      action.signupFailedActionCreator({
        message: NOT_AGREE_TERMS_AND_CONDITION,
      })
    );
    return false;
  }
  if (!isPrivacyPolicy.value) {
    dispatch(
      action.signupFailedActionCreator({ message: NOT_AGREE_PRIVACY_POLICY })
    );
    return false;
  }

  return true;
};

/**
 * Sign up new user
 * @param {Object} name - name (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} affiliation - affiliation (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} spec - spec (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} mprn - medical practitioner reg num (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} email - email (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} psw - psw (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} isCompany - medical practitioner reg num (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} companyName - company name(object: valid - boolean, touched - boolean, value: string)
 * @param {Object} companyAddress - company address (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} companyVAT - company vat num (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} address - user adrres (object: valid - boolean, touched - boolean, value: string)
 ** @param {Function} dispatch - dispatch to dispatch to redux
 */
export const signup = (
  name,
  affiliation,
  spec,
  mprn,
  email,
  psw,
  isCompany,
  companyName,
  companyAddress,
  companyVAT,
  address,
  dispatch
) => {
  if (dispatch) {
    dispatch(action.singupStartActionCreator());
  }

  dermusAxios
    .post(
      SIGN_UP_EP,
      qs.stringify(
        createSignUpBody(
          name.value,
          affiliation.value,
          spec.value,
          mprn.value,
          email.value,
          psw.value,
          isCompany.value,
          companyName.value,
          companyAddress.value,
          companyVAT.value,
          address.value
        )
      ),
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      }
    )
    .then(() => dispatch(action.signupSuccessActionCreator()))
    .catch((e) => {
      if (e.response && e.response.data) {
        dispatch(action.signupFailedActionCreator(e.response.data));
        return;
      }
      dispatch(action.signupFailedActionCreator(e));
    });
};

/**
 * Reset password
 * @param {Object} email email (object: valid - boolean, touched - boolean, value: string)
 * @param {Function} dispatch dispatch to dispatch to redux
 */
export const resetPassword = (email, dispatch) => {
  if (!email.valid || !email.touched) {
    dispatch(action.resetPswFailedActionCreator({ message: ADD_VALID_EMAIL }));
    return;
  }
  dispatch(action.resetPswStartActionCreator());

  dermusAxios
    .post(FORGOT_PSW_EP, qs.stringify(createForgotPswBody(email.value)))
    .then(() => dispatch(action.resetPwsConfirm()))
    .catch((err) => {
      dispatch(action.resetPswFailedActionCreator(err));
    });
};

/**
 * Confirm password (after reset pws)
 * @param {Object} email email (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} verificationCode verification code (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} newPassword new password (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} newPasswordConf  new password again (object: valid - boolean, touched - boolean, value: string)
 * @param {Function} dispatch  dispatch to dispatch to redux
 * @param {Function} onSuccess function to call on success
 */
export const confirmPassword = (
  email,
  verificationCode,
  newPassword,
  newPasswordConf,
  dispatch,
  onSuccess
) => {
  if (!email.valid || !email.touched) {
    dispatch(action.resetPswFailedActionCreator({ message: ADD_VALID_EMAIL }));
    return;
  }
  if (!newPassword.valid || !newPassword.touched) {
    dispatch(action.resetPswFailedActionCreator({ message: ADD_VALID_PSW }));
    return;
  }
  if (newPasswordConf.value !== newPassword.value) {
    dispatch(action.resetPswFailedActionCreator({ message: PSWS_NOT_SAME }));
    return;
  }
  if (!verificationCode.valid || !verificationCode.touched) {
    dispatch(
      action.resetPswFailedActionCreator({
        message: ADD_VALID_VERIFICATION_CODE,
      })
    );
    return;
  }
  dispatch(action.resetPswStartActionCreator());

  dermusAxios
    .put(
      FORGOT_PSW_EP,
      qs.stringify(
        createConfirmPswBody(
          email.value,
          verificationCode.value,
          newPassword.value
        )
      )
    )
    .then(() => {
      dispatch(action.clearAuthStateActionCreator());
      onSuccess();
    })
    .catch((err) => {
      dispatch(action.resetPswFailedActionCreator(err));
    });
};

import { ReactSVG } from "react-svg";
import styles from "./Icon.module.css";
import { NavLink } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { useEffect } from "react";

const Icon = (props) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  });
  return (
    <div
      className={styles.Icon}
      data-cy={props.datacy}
      onClick={props.onClick ? props.onClick : () => {}}
    >
      <NavLink data-tip={props.hint} to={props.imgLink ? props.imgLink : "#"}>
        <ReactSVG src={props.iconImage} />
      </NavLink>
    </div>
  );
};

export default Icon;

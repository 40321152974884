import { memo } from "react";
import PropTypes from "prop-types";

import GeneralModal from "./GeneralModal";

const ErrorModal = memo((props) => {
  return props.error ? (
    <GeneralModal
      onClose={props.onClose}
      title={props.title}
      onConfirm={props.onConfirm}
    >
      {props.error}
    </GeneralModal>
  ) : null;
});
ErrorModal.propTypes = {
  error: PropTypes.string,
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
};
export default ErrorModal;

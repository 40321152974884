import * as actionType from "../actions/patientManagementActions";

const initState = {
  patient: null,
  record: null,
  importedOptImg: null,
  lesion: null,
  stale: false,
};

const patientManagementReducer = (state = initState, action) => {
  switch (action.type) {
    case actionType.SET_PATIENT_INFO:
      return {
        ...state,
        patient: { ...action.payload.patient },
      };
    case actionType.SET_RECORD_INFO:
      return {
        ...state,
        record: action.payload.record ? { ...action.payload.record } : null,
      };
    case actionType.SET_LESION_INFO:
      return {
        ...state,
        lesion: { ...action.payload.lesion },
      };
    case actionType.SET_NOTES_TO_RECORD:
      const record = { ...state.record, notes: [...action.payload.notes] };
      return {
        ...state,
        record,
      };
    case actionType.SET_NOTE_TO_LESION:
      const lesion = { ...state.lesion };
      lesion.note = action.payload.note.slice();
      return {
        ...state,
        lesion,
      };
    case actionType.SET_DIAGNOSIS_TO_LESION:
      const lesionToDiagnosis = { ...state.lesion };
      lesionToDiagnosis.diagnosis = action.payload.diagnosis;
      return {
        ...state,
        lesion: lesionToDiagnosis,
      };
    case actionType.SERVE_FROM_STALE:
      return { ...state, stale: action.payload.stale };
    default:
      return state;
  }
};

export default patientManagementReducer;

import { Fragment } from "react";
import PropTypes from "prop-types";

import Logo from "../../Logo/Logo";

import styles from "./SideDrawer.module.css";
import Backdrop from "../../Backdrop/Backdrop";
import AppNameWithVersion from "../AppNameWithVersion";
import { SKINAID_APP_NAME } from "../../../Constants/Constants";
import { ADMIN } from "../../../Constants/Message";

/**
 * sideDrawer
 * @param {Object} props {isOpen: open or not (boolean), appVersion: app version, onClose: on close function (function), isAuthenticated:  auth or not (boolean), hide: hde menu (boolean)}
 */
const sideDrawer = (props) => {
  let attachedStyle = [styles.SideDrawer, styles.Close];
  if (props.isOpen) {
    attachedStyle = [styles.SideDrawer, styles.Open];
  }
  return (
    <Fragment>
      {props.hide ? null : (
        <Fragment>
          <Backdrop show={props.isOpen} onClick={props.onClose} />
          <div className={attachedStyle.join(" ")} onClick={props.onClose}>
            <div className={styles.Logo}>
              <Logo />
            </div>
            <AppNameWithVersion
              appName={SKINAID_APP_NAME}
              info={ADMIN}
              appVersion={props.appVersion}
            />
            <nav>{props.children}</nav>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

sideDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  hide: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  isAuthenticated: PropTypes.bool.isRequired,
  appVersion: PropTypes.string,
};

export default sideDrawer;

export const SKINAID_APP_NAME = "Skin Aid";
export const MANUFACTURER_MODEL_NAME = "Dermus Ltd";
export const USER_SIGNED_IN_LOCAL_STORAGE = "userSignedIn";
export const LAST_EDITED_LESION_STORAGE = "lastLesion";

export const TERMS_AND_CONDITION_CURRENT_LINK =
  "https://skinaid-public-docs.s3.eu-central-1.amazonaws.com/Terms.pdf";
export const PRIVACY_POLICY_CURRENT_LINK =
  "https://skinaid-public-docs.s3.eu-central-1.amazonaws.com/PrivacyPolicy.pdf";
export const TERMS_AND_CONDITION_PREV_LINK =
  "http://skinaid-public-docs.s3-website.eu-central-1.amazonaws.com/PrevTerms/";
export const PRIVACY_POLICY_PREV_LINK =
  "http://skinaid-public-docs.s3-website.eu-central-1.amazonaws.com/PrevPrivacyPolicy/";
export const USER_MANUAL_LINK =
  "https://skinaid-public-docs.s3.eu-central-1.amazonaws.com/UserManual.pdf";

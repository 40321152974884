import { useState, useCallback, useRef } from "react";

import { checkValidity } from "../utility";
import { EMAIL, PASSWORD } from "../Constants";
import styles from "./UserInput.module.css";

export const EMAIL_TYPE = {
  placeholder: EMAIL,
  validation: {
    required: true,
    isEmail: true,
  },
  type: "email",
};
export const PSW_TYPE = {
  placeholder: PASSWORD,
  validation: {
    required: true,
  },
  type: "password",
};
export const NUM_TYPE = {
  placeholder: "",
  validation: {
    required: true,
  },
  type: "number",
};

export const MAX_LENGTH_ENCRPYTED_INPUT = 100; //This is the limit of the cloud with encryption
const MAX_LENGTH_INPUT = 200; //This is the limit of the cloud
/**
 * User input
 * @param {Object} props {value: string, placeholder: string, validation: object, type: string, onEnter: on enter (function), submitted: submitted or not}
 */
const useUserInput = (props) => {
  //Update lesion note state
  const [state, setState] = useState({
    value: props.value ? props.value : "",
    valid: props.valid ? true : false,
    touched: props.touched ? true : false,
  });
  //Ref
  const inputRef = useRef();
  //Configuration
  const [config] = useState({
    type: props.type ? props.type : "text",
    placeholder: props.placeholder ? props.placeholder : "",
    validation: props.validation ? props.validation : { required: true },
    maxLength: props.maxLength ? props.maxLength : MAX_LENGTH_INPUT,
  });
  //Style
  const inputClasses = [styles.InputElement];
  if (!state.valid && (state.touched || props.submitted)) {
    inputClasses.push(styles.Invalid);
  }
  //Input handler
  const inputChangedHandler = useCallback(
    (event) => {
      const newValue = {
        value: event.target.value,
        valid: checkValidity(event.target.value, config.validation),
        touched: true,
      };
      setState(newValue);
    },
    [config]
  );

  return [
    <div className={styles.Input}>
      <input
        ref={inputRef}
        className={inputClasses.join(" ")}
        value={state.value}
        onChange={(event) => inputChangedHandler(event)}
        type={config.type}
        placeholder={config.placeholder}
        onKeyPress={(event) =>
          event.key === "Enter" && props.onEnter ? props.onEnter(state) : null
        }
        maxLength={config.maxLength}
      />
    </div>,
    state,
    setState,
    inputRef,
  ];
};

export default useUserInput;

import PropTypes from "prop-types";

import styles from "./Button.module.css";

const Button = (props) =>
  props.disabled ? (
    <button
      onClick={props.onClick ? props.onClick : null}
      className={styles.Button}
      data-cy="button"
      disabled
      type={props.type ? props.type : "submit"}
    >
      {props.title}
    </button>
  ) : (
    <button
      onClick={props.onClick ? props.onClick : null}
      className={styles.Button}
      data-cy="button"
      type={props.type ? props.type : "submit"}
    >
      {props.title}
    </button>
  );

Button.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.string,
};
export default Button;

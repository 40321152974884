import axios from "axios";
import { responseHandler, requestHandler, errorHandler } from "./axiosUtil";

const whiteList = [];
const MAX_AGE = 7 * 24 * 60 * 60 * 1000; //millisec  -- 1 week
const MAX_STALE = 7 * 24 * 60 * 60 * 1000; //millisec  -- 1 week

const instance = axios.create();

instance.interceptors.request.use(
  (request) => requestHandler(request, MAX_AGE, MAX_STALE),
  (error) => errorHandler(error, MAX_AGE, MAX_STALE)
);
instance.interceptors.response.use(
  (response) => responseHandler(response, whiteList),
  (error) => errorHandler(error, MAX_AGE, MAX_STALE)
);

export default instance;

import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import authenticationReducer from "./reducers/authenticationReducer";
import patientManagementReducer from "./reducers/patientManagementReducer";

const reducer = (auth, patMan) =>
  combineReducers({
    auth: auth ? authenticationReducer : null,
    patMan: patMan ? patientManagementReducer : null,
  });

/**
 * Create own store
 * @param auth use auth or not
 * @param patMan use patMan or not
 * @returns store
 */
const createOwnStore = (auth, patMan) => {
  const store = createStore(reducer(auth, patMan), applyMiddleware(thunk));
  return store;
};

export default createOwnStore;

import { Fragment, useEffect, useState } from "react";
import moment from "moment";
import Papa from "papaparse";
import Select from "react-select";

import {
  ADMIN_MEASUREMENTS_EP,
  ADMIN_RECIRD_STAT_FILE_EP,
  ADMIN_RECORD_STATS_ASYNC_EP,
  ADMIN_RECORD_STATS_FILTERED_EP,
  USERS_EP,
} from "../../dermusbase/API/endPoints";
import useHttp, { GET } from "../../dermusbase/API/useHttp";
import { EXOPRT_ALL, ERROR_OCCURRED } from "../../dermusbase/Constants/Message";
import Row from "../../dermusbase/UI/Divs/Row/Row";
import Container from "../../dermusbase/UI/Divs/Container/Container";
import useElementSize from "../../dermusbase/hooks/useElementSize";
import Col from "../../dermusbase/UI/Divs/Col/Col";
import ErrorModal from "../../dermusbase/UI/Modal/ErrorModal";
import Spinner from "../../dermusbase/UI/Spinner/Spinner";

import CustomDateRangePicker, { CALENDAR_WIDTH } from "../DateRangePicker";
import DownloadButton from "../../dermusbase/UI/Button/DownloadButton";
import {
  ALL_NEW_LESION_NUM,
  ALL_NEW_MEASUREMENTS_NUM,
  ALL_NEW_RECORD_NUM,
  ALL_RECORD_DATA_CSV,
  NEW_LESION_NUM,
  NEW_MEASUREMENTS_NUM,
  NEW_RECORD_NUM,
  ONLY_SSU,
} from "../../Constants/Message";

import style from "./BaseStat.module.css";
import Histogram from "../../dermusbase/UI/Charts/CustomChart";
import useCheckbox from "../../dermusbase/UI/CustomInput/useCheckbox";

const ALL_USER_AND_NONE_USER = {
  value: "all_and_no",
  label: "All users + No user",
};
const ALL_USER_WITHOUT_NO = {
  value: "all_without_no",
  label: 'All users (without "No user")',
};
const NO_USER = { value: "None", label: "No user" };
const last3days = { value: "last3days", label: "Last 3 days" };
const last7days = { value: "last7days", label: "Last 7 days" };
const customValue = { value: "customValue", label: "Custom" };
const last14days = { value: "last14days", label: "Last 14 days" };
const lastMonth = { value: "lastMonth", label: "Last month" };
const prevMonth = { value: "prevMonth", label: "Previous month" };
const actMonth = { value: "actMonth", label: "Actual month" };
const DATE_TYPES = [
  customValue,
  last3days,
  last7days,
  last14days,
  lastMonth,
  prevMonth,
  actMonth,
];

const RECORD_MODE = { label: "Record", value: "Record" }
const LESION_MODE = { label: "Lesion", value: "Lesion" }
const MEASUREMENT_MODE = { label: "Measurements", value: "Measurements" }
const MODES = [RECORD_MODE, LESION_MODE, MEASUREMENT_MODE]
//Histogram vs All rec ratio
const ratioHistAndAll = 0.8;
//date and user selector ratio
const ratioDateAndUser = 0.7;
// Min width of user selector
const MIN_SEL_WIDTH = 350;
//Chart height
const CHART_HEIGHT = 400;
//Threshol, hist and all are in row or col
const HIST_ALL_COL_OR_ROW_TH = 550;

const DEFAULT_STYLE_OF_BAR = {
  backgroundColor: ["#e2304c33"],
  borderColor: ["#e2304c"],
  borderWidth: 1,
};

/**
 *Filter data based on user and daterange "order by" record
 * @param {Array<Object>} data array of obj (date and user_id)
 * @param {Arrray<{value, label}>} users users
 * @param {Object} dateRange date range (startDate, endDate)
 * @returns filtered array
 */
const filterRecordData = (data, users, dateRange, onlySSU) => {
  let dataToFilter = [...data];
  dataToFilter = dataToFilter.filter((item) => {
    //Filter based on user
    // all user with no user
    let tmp = users.filter(u => u.value.toLowerCase() === ALL_USER_AND_NONE_USER.value.toLowerCase()).length;
    if (tmp === 0) {
      //all user wihtout no user
      tmp = users.filter(u => u.value.toLowerCase() === ALL_USER_WITHOUT_NO.value.toLowerCase()).length;
      if (tmp > 0) {
        //not no user
        if (item.user_id.toLowerCase() === NO_USER.value.toLowerCase()) {
          return false;
        }
      } else {
        tmp = users.filter(u => u.value.toLowerCase() === item.user_id.toLowerCase()).length;
        if (tmp === 0) {
          //given user
          return false;
        }
      }
    }
    //Filter based on data
    if (
      !(
        (item.date > dateRange.startDate && item.date < dateRange.endDate) ||
        item.date.isSame(moment(dateRange.startDate), "day") ||
        item.date.isSame(moment(dateRange.endDate), "day")
      )
    ) {
      return false;
    }

    if (onlySSU.value && !item.ssu) {
      return false
    }
    return true;
  });
  return dataToFilter;
};

/**
 *Filter data based on user and daterange "order by" lesion
 * @param {Array<Object>} data array of obj (date and user_id)
 * @param {Arrray<{value, label}>} users users
 * @param {Object} dateRange date range (startDate, endDate)
 * @returns filtered array
 */
const filterLesionData = (data, users, dateRange, onlySSU) => {
  let dataToFilter = {}
  data.forEach(item => {
    if (dataToFilter[item.lesion_id]) {
      if (dataToFilter[item.lesion_id].date > item.date) {
        const SSU = dataToFilter[item.lesion_id].numSSU
        const notSSU = dataToFilter[item.lesion_id].numNotSSU
        dataToFilter[item.lesion_id] = item
        dataToFilter[item.lesion_id].numSSU = SSU + item.ssu ? 1 : 0
        dataToFilter[item.lesion_id].numNotSSU = notSSU + item.ssu ? 0 : 1
      }
    } else {
      dataToFilter[item.lesion_id] = item
      dataToFilter[item.lesion_id].numSSU = item.ssu ? 1 : 0
      dataToFilter[item.lesion_id].numNotSSU = item.ssu ? 0 : 1
    }
  })
  dataToFilter = Object.values(dataToFilter)

  dataToFilter = dataToFilter.filter((item) => {
    //Filter based on user
    // all user with no user
    let tmp = users.filter(u => u.value.toLowerCase() === ALL_USER_AND_NONE_USER.value.toLowerCase()).length;
    if (tmp === 0) {
      //all user wihtout no user
      tmp = users.filter(u => u.value.toLowerCase() === ALL_USER_WITHOUT_NO.value.toLowerCase()).length;
      if (tmp > 0) {
        //not no user
        if (item.user_id.toLowerCase() === NO_USER.value.toLowerCase()) {
          return false;
        }
      } else {
        tmp = users.filter(u => u.value.toLowerCase() === item.user_id.toLowerCase()).length;
        if (tmp === 0) {
          //given user
          return false;
        }
      }
    }
    //Filter based on date
    if (
      !(
        (item.date > dateRange.startDate && item.date < dateRange.endDate) ||
        moment(item.date).isSame(dateRange.startDate, "day") ||
        moment(item.date).isSame(dateRange.endDate, "day")
      )
    ) {
      return false;
    }

    //Filter based on SSU
    if (onlySSU.value && ((item.numNotSSU > item.numSSU) || (item.numSSU === 0 && item.numNotSSU === 0))) {
      return false
    }
    return true;
  });
  return dataToFilter;
};

/**
 *Filter data based on user and daterange "order by" measurement
 * @param {Array<Object>} data array of obj (date and user_id)
 * @param {Arrray<{value, label}>} users users
 * @param {Object} dateRange date range (startDate, endDate)
 * @returns filtered array
 */
const filterMeasurementsData = (data, users, dateRange) => {
  let dataToFilter = [...data];
  dataToFilter = dataToFilter.filter((item) => {
    //Filter based on user
    // all user with no user
    let tmp = users.filter(u => u.value.toLowerCase() === ALL_USER_AND_NONE_USER.value.toLowerCase()).length;
    if (tmp === 0) {
      //all user wihtout no user
      tmp = users.filter(u => u.value.toLowerCase() === ALL_USER_WITHOUT_NO.value.toLowerCase()).length;
      if (tmp > 0) {
        //not no user
        if (item.user_id.toLowerCase() === NO_USER.value.toLowerCase()) {
          return false;
        }
      } else {
        tmp = users.filter(u => u.value.toLowerCase() === item.user_id.toLowerCase()).length;
        if (tmp === 0) {
          //given user
          return false;
        }
      }
    }
    //Filter based on data
    if (
      !(
        (item.date > dateRange.startDate && item.date < dateRange.endDate) ||
        item.date.isSame(moment(dateRange.startDate), "day") ||
        item.date.isSame(moment(dateRange.endDate), "day")
      )
    ) {
      return false;
    }
    return true;
  });
  const newData = []
  dataToFilter.forEach(item => {
    if (Array.isArray(item.data)) {
      const items = [...item.data]
      items.forEach(meas => {
        const newItem = { ...item, data: meas }
        newData.push(newItem)
      })
    } else {
      newData.push(item)
    }
  })
  return dataToFilter;
};


/**
 * Binning data
 * @param {Array<Object>} data array of object(date)
 * @param {Object} dateRange date range (startDate, endDate)
 * @returns binning data [{x,y}]
 */
const generateDataForChart = (data, dataRange) => {
  const st = moment(dataRange.startDate).startOf("day"),
    et = moment(dataRange.endDate).startOf("day").add(1, "days");
  const numOfBin = Math.abs(et.diff(st, "days"));
  const dataToDisplay = new Array(numOfBin);
  for (let i = 0; i < numOfBin; i++) {
    dataToDisplay[i] = {
      x: moment(st).add(i, "days").format("DD/MM/YYYY"),
      y: 0,
    };
  }
  data.forEach((item) => {
    const ind = moment(item.date).startOf("day").diff(st, "days");
    dataToDisplay[ind].y = dataToDisplay[ind].y + 1;
  });
  return dataToDisplay;
};
/**
 * Set date range based on the selected date type
 * @param {String} dateType date type
 * @param {Function} setState set state
 * @returns
 */
const setDateRangeBasedonDateType = (dateType, setState) => {
  let startDate = moment().subtract(7, "days");
  let endDate = moment();
  if (dateType === last3days.value) {
    startDate = moment().subtract(2, "days");
  } else if (dateType === last7days.value) {
    startDate = moment().subtract(6, "days");
  } else if (dateType === last14days.value) {
    startDate = moment().subtract(13, "days");
  } else if (dateType === lastMonth.value) {
    startDate = moment().subtract(1, "months");
  } else if (dateType === actMonth.value) {
    startDate = moment().startOf("month");
  } else if (dateType === prevMonth.value) {
    startDate = moment().subtract(1, "months").startOf("month");
    endDate = moment().subtract(1, "months").endOf("month");
  } else {
    return;
  }
  setState([
    {
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
      key: "selection",
    },
  ]);
};
/**
 * Create data for user selection
 * @param {Array<Object>} usersData array of obj (name, email, username)
 * @param {String} mode selected mode(rec, les, measurement)
 * @return data arra fos useUserSelector
 */
const createDataForUserSelection = (usersData, data, filterFunction, dateRange, extra) => {
  let newUserData = [...usersData]
  newUserData.forEach(
    (item, ind) =>
    (newUserData[ind] = {
      label: `${item.name} (${item.email})`,
      value: item.username,
    })
  );
  newUserData.sort((a, b) => (a.label < b.label ? -1 : 1));
  newUserData = [{ ...ALL_USER_AND_NONE_USER }, { ...NO_USER }, { ...ALL_USER_WITHOUT_NO }].concat(newUserData);

  newUserData.forEach((item, index) => {
    const nummOfData = filterFunction(data, [item], dateRange, extra).length;
    newUserData[index].label = newUserData[index].label + ` - ${nummOfData}`
  })
  return newUserData;
};

const BaseStat = () => {
  //Selected date range
  const [dateState, setDateState] = useState([
    {
      startDate: moment({ year: 2021, month: 9, day: 1 }).toDate(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  // Original data
  const [recordData, setRecordData] = useState([]);
  //Filtered  data
  const [filteredData, setFilteredData] = useState([]);
  //Get stats
  const [
    dataGetStats,
    sendRequestGetStats,
    cancelGetStats,
    ,
    errorGetStats,
    clearGetStats,
  ] = useHttp();
  //Get stats
  const [
    dataGetStatsFile,
    sendRequestGetStatsFile,
    cancelGetStatsFile,
    ,
    errorGetStatsFile,
    clearGetStatsFile,
  ] = useHttp();

  //Get stats
  const [
    dataGetStatsLess,
    sendRequestGetStatsLess,
    cancelGetStatsLess,
    pendingGetStatsLess,
    errorGetStatsLess,
    clearGetStatsLess,
  ] = useHttp();
  //Get list of users user
  const [
    users,
    sendRequestGetUsers,
    cancelGetUsers,
    pendingGetUsers,
    errorGetUsers,
    clearGetUsers,
  ] = useHttp();
  //Get list of measurements
  const [
    measurementsData,
    sendRequestGetMeasurements,
    cancelGetMeasurements,
    pendingGetMeasurements,
    errorGetMeasurements,
    clearGetMeasurements,
  ] = useHttp();

  //Mode (Record, Lesion, Request)
  const [modeSelector] = useState(MODES);
  //Selected mode
  const [selectedMode, setSelectedMode] = useState(RECORD_MODE);
  //Data to plot
  const [chartData, setChartData] = useState([]);
  //Ref to Hist and All data div
  const [refHistAndAll, dimensionsHistAndAll] = useElementSize();
  //Ref to date and user div
  const [refDateAndUser, dimensionsDateAndUser] = useElementSize();
  //User selector options
  const [userSelectorValues, setUserSelectorValues] = useState(null);
  //Selected user(s)
  const [selectedUser, setSelectedUser] = useState([ALL_USER_AND_NONE_USER]);
  //Selected dateType
  const [selectedDateType, setSelectedDateType] = useState(customValue);
  //User Aggrement checkbox
  const [ssuCheckbox, onlySSU] = useCheckbox({
    label: ONLY_SSU,
    validation: {},
  });

  //Download and parse record data when url of csv arrived
  useEffect(() => {
    if (dataGetStatsLess?.url) {
      const data = [];
      Papa.parse(dataGetStatsLess?.url, {
        download: true,
        header: true,
        step: (row) => {
          if (row?.data?.user_id !== "") {
            data.push({ ...row.data, date: moment(row.data.date + ".000Z"), ssu: row.data.device_name === "SS-M" });
          }
        },
        complete: () => setRecordData(data),
      });
    }
  }, [dataGetStatsLess]);

  //Filter the records when selected user, selected date or arrived data is changed
  useEffect(() => {
    if (recordData) {
      if (selectedMode.value === RECORD_MODE.value) {
        setFilteredData(
          filterRecordData(recordData, selectedUser, dateState[0], onlySSU)
        );
      } else if (selectedMode.value === LESION_MODE.value) {
        setFilteredData(
          filterLesionData(recordData, selectedUser, dateState[0], onlySSU)
        );
      } else if (selectedMode.value === MEASUREMENT_MODE.value) {
        setFilteredData(
          filterMeasurementsData(measurementsData, selectedUser, dateState[0])
        );
      }
    }
  }, [selectedUser, dateState, recordData, selectedMode, measurementsData, onlySSU]);

  useEffect(() => {
    if (recordData !== null && selectedMode?.value !== null && dateState && users !== null) {
      switch (selectedMode.value) {
        case RECORD_MODE.value:
          setUserSelectorValues(createDataForUserSelection(users, recordData, filterRecordData, dateState[0], onlySSU));
          break;
        case LESION_MODE.value:
          setUserSelectorValues(createDataForUserSelection(users, recordData, filterLesionData, dateState[0], onlySSU));
          break;
        case MEASUREMENT_MODE.value:
          setUserSelectorValues(createDataForUserSelection(users, measurementsData, filterMeasurementsData, dateState[0]));
          break;
        default:
          break;
      }
    }
  }, [recordData, selectedMode, dateState, users, measurementsData, onlySSU])

  //Generate data to Chart when filteres data is changed
  useEffect(() => {
    if (filteredData !== null) {
      setChartData(generateDataForChart(filteredData, dateState[0]));
    } // eslint-disable-next-line
  }, [filteredData]);
  //Send request (data and user and measurement) on start
  useEffect(() => {
    sendRequestGetStats(ADMIN_RECORD_STATS_ASYNC_EP, GET, true, (data) => {
      return data
    });
    sendRequestGetStatsLess(ADMIN_RECORD_STATS_FILTERED_EP, GET, true, (data) => {
      return data
    });
    sendRequestGetUsers(USERS_EP, GET, true, (data) => {
      return data;
    });
    sendRequestGetMeasurements(ADMIN_MEASUREMENTS_EP, GET, true, (data) => {
      data.forEach((item, index) => {
        data[index].date = moment(data[index].date);
      })
      return data;
    });
    return () => {
      cancelGetStats();
      cancelGetStatsLess()
      cancelGetUsers();
      cancelGetMeasurements();
      cancelGetStatsFile();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (dataGetStats) {
      sendRequestGetStatsFile(`${ADMIN_RECIRD_STAT_FILE_EP}/${dataGetStats}`, GET, true)
    }// eslint-disable-next-line
  }, [dataGetStats])
  useEffect(() => {
    if (dataGetStatsFile && !dataGetStatsFile.url) {
      if (dataGetStats) {
        setTimeout(() => sendRequestGetStatsFile(`${ADMIN_RECIRD_STAT_FILE_EP}/${dataGetStats}`, GET, true), 5000)
      }
    }// eslint-disable-next-line
  }, [dataGetStatsFile])


  //Set selected date range when date type is changed
  useEffect(() => {
    setDateRangeBasedonDateType(selectedDateType.value, setDateState);
  }, [selectedDateType]);

  //Date and user component
  const dateAndUserComponenet = (widthDate, widthUser) =>
    widthDate &&
    widthUser && (
      <Fragment>
        <Col>
          <div className={style.Select} style={{ width: widthDate }}>
            <Select
              onChange={(selectedOption) => setSelectedDateType(selectedOption)}
              value={selectedDateType}
              options={DATE_TYPES}
              isSearchable={false}
            />
          </div>
          {selectedDateType.value === customValue.value && (
            <CustomDateRangePicker
              width={widthDate}
              state={dateState}
              setState={setDateState}
            />
          )}
        </Col>
        <div className={style.Select} style={{ width: widthUser }}>
          <Select
            onChange={(selectedOption) => setSelectedUser(selectedOption)}
            value={selectedUser}
            isMulti
            options={userSelectorValues}
          />
        </div>
      </Fragment>
    );


  //All rec component
  const allRecComponent = (isCol) => (
    <Col>
      <span className={style.AllData}>
        {
          selectedMode.value === RECORD_MODE.value ? ALL_NEW_RECORD_NUM : selectedMode.value === LESION_MODE.value ? ALL_NEW_LESION_NUM : ALL_NEW_MEASUREMENTS_NUM
        }: {filteredData.length}
      </span>
      <Select
        onChange={(selectedOption) => setSelectedMode(selectedOption)}
        value={selectedMode}
        options={modeSelector}
      />

      {(selectedMode.value === RECORD_MODE.value || selectedMode.value === LESION_MODE.value) && ssuCheckbox}
    </Col>
  );
  //Hist and all component
  const histAndAllComponent = (isCol) =>
    dimensionsHistAndAll?.width && (
      <Fragment>
        <Histogram
          width={
            isCol
              ? dimensionsHistAndAll
              : dimensionsHistAndAll.width * ratioHistAndAll
          }
          height={CHART_HEIGHT}
          dataset={{
            ...DEFAULT_STYLE_OF_BAR,
            label: selectedMode.value === RECORD_MODE.value ? NEW_RECORD_NUM : selectedMode.value === LESION_MODE.value ? NEW_LESION_NUM : NEW_MEASUREMENTS_NUM,
            data: chartData,
          }}
        />
        {isCol ? (
          <Row width={dimensionsHistAndAll}>{allRecComponent(!isCol)}</Row>
        ) : (
          <Col width={dimensionsHistAndAll.width * (1 - ratioHistAndAll)}>
            {allRecComponent(isCol)}
          </Col>
        )}
      </Fragment>
    );

  return (
    <Container>
      {pendingGetStatsLess || pendingGetUsers || pendingGetMeasurements ? (
        <Spinner />
      ) : (
        <Fragment>
          <div ref={refHistAndAll}>
            {dimensionsHistAndAll?.width &&
              (dimensionsHistAndAll?.width < HIST_ALL_COL_OR_ROW_TH ? (
                <Col>{histAndAllComponent(true)}</Col>
              ) : (
                <Row>{histAndAllComponent(false)}</Row>
              ))}
          </div>
          <Fragment> <div ref={refDateAndUser}>
            {dimensionsDateAndUser?.width &&
              (dimensionsDateAndUser.width * ratioDateAndUser <
                2 * CALENDAR_WIDTH ||
                dimensionsDateAndUser.width * (1 - ratioDateAndUser) <
                MIN_SEL_WIDTH ? (
                <Col>
                  {dateAndUserComponenet(
                    dimensionsDateAndUser.width,
                    dimensionsDateAndUser.width
                  )}
                </Col>
              ) : (
                <Row>
                  {dateAndUserComponenet(
                    dimensionsDateAndUser.width * ratioDateAndUser,
                    dimensionsDateAndUser.width * (1 - ratioDateAndUser)
                  )}
                </Row>
              ))}
          </div>

            <DownloadButton
              pending={!dataGetStatsFile?.url}
              title={EXOPRT_ALL}
              data={dataGetStatsFile?.url}
              fileName={ALL_RECORD_DATA_CSV}
            />
          </Fragment>
        </Fragment>
      )}
      <ErrorModal
        onClose={() => {
          clearGetStats();
          clearGetUsers();
          clearGetMeasurements();
          clearGetStatsLess();
          clearGetStatsFile()
        }}
        title={ERROR_OCCURRED}
        error={errorGetStats || errorGetUsers || errorGetMeasurements || errorGetStatsFile || errorGetStatsLess}
      />
    </Container>
  );
};

export default BaseStat;

import {
  ONLY_OPT,
  ANNOTATION_FLAG,
  ANNOTATION_US_FLAG,
} from "../Constants/Message";
import { DICOM_EXT } from "./Constants";

import axios from "axios";
import dermusAxios from "../API/dermusAxios";
import icdAxios from "../API/icdAxios";
import { ICD_EP, ICD_TOKEN_EP } from "../API/endPoints";

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const getEnv = () =>
  process.env.REACT_APP_ENVIRONMENT
    ? process.env.REACT_APP_ENVIRONMENT.localeCompare("prod") !== 0
    : true;

//Only optical image? based on note (backward) and us image
export const isOnlyOptical = (record) => {
  if (!record.usImId) {
    return true;
  }
  let onlyOpt = false;
  //Notes solution: to be backcompatible
  record.notes.forEach((element) => {
    if (element.note && element.note.startsWith(ONLY_OPT)) {
      onlyOpt = true;
    }
  });
  return onlyOpt;
};

//is Dicom extension
export const isDicomExt = (name) => {
  return name.endsWith(DICOM_EXT);
};

//Build serach url
export const buildSearchQuery = (params) => {
  const urlWithParams = new URLSearchParams();
  if (params) {
    Object.entries(params).forEach((element) =>
      urlWithParams.append(element[0], element[1])
    );
  }
  return urlWithParams;
};
//Notes contains annotation or not
export const alreadyAnnotated = (notes) => {
  if (!notes) {
    return false;
  }
  let annotated = false;
  notes.forEach((element) => {
    if (
      element.note &&
      (element.note.startsWith(ANNOTATION_FLAG) ||
        element.note.startsWith(ANNOTATION_US_FLAG))
    ) {
      annotated = true;
    }
  });
  return annotated;
};

//Get not specific note (ONLY_OPTICAL, ANNOTATION) as string
export const getNotSpecificNotesAsString = (notes) => {
  let output = "[";
  let firstNote = true;
  notes.forEach((e, i) => {
    if (
      e.note &&
      !e.note.startsWith(ANNOTATION_FLAG) &&
      !e.note.startsWith(ONLY_OPT) &&
      !e.note.startsWith(ANNOTATION_US_FLAG)
    ) {
      output =
        output +
        (firstNote ? "" : ",") +
        '{"note":"' +
        e.note +
        '","date":"' +
        e.date +
        '"}';
      firstNote = false;
    }
  });
  output = output + "]";
  return output;
};

/**
 *
 * @param {Number} containerWidth width of the container
 * @param {Image} img image
 * @param {Number} usWidthMM width of the real us image (mm)
 * @param {Number} optWidthMM width of the real opt image (mm)
 * @param {Boolean} onlyOpt only optocal img
 */
export const calculateOptContainerSize = (
  containerWidth,
  img,
  usWidthMM,
  optWidthMM,
  onlyOpt
) => {
  const width = Math.min(containerWidth * 0.8, 640);
  const padding = onlyOpt
    ? 0
    : usWidthMM < optWidthMM
    ? 0
    : ((usWidthMM - optWidthMM) / usWidthMM) * width;
  const height = ((width - padding) / img.width) * img.height;
  return { width: ~~width, height: ~~height, padding: ~~padding };
};
/**
 * Calculate US container size
 * @param {Number} containerWidth width of the container
 * @param {Image} img image
 */
export const calculateUsContainerSize = (containerWidth, img) => {
  const width = Math.min(containerWidth * 0.8, 640);
  const height = (width / img.width) * img.height;
  return { width: ~~width, height: ~~height };
};

export const getHeader = (token) => ({
  headers: {
    Authorization: "Bearer " + token,
    "Content-Type": "application/json",
    "API-Version": "v2",
    "Accept-Language": "en",
  },
});

/**
 * decode diagnosis
 * @param {String} diagnosisCodes diagnosis code
 * @param {String} dermusToken dermus token
 * @param {Function} onDecoded ondecoded functon, input : decoded string
 */
export const decodeDiagnosisCode = (
  diagnosisCodes,
  dermusToken,
  onDecoded,
  onError
) => {
  dermusAxios //Get token
    .get(ICD_TOKEN_EP, {
      headers: {
        Authorization: dermusToken,
      },
    })
    .then((resp) => {
      const token = resp.data.access_token;
      const codes = diagnosisCodes ? diagnosisCodes : "";
      let codesArray = codes.split("/");
      if (codesArray.length > 0) {
        codesArray = codesArray[0].split("&");
        axios //Get stemid from code
          .all(
            codesArray.map((item) =>
              icdAxios.get(ICD_EP + item, getHeader(token))
            )
          )
          .then(
            axios.spread((...responses) => {
              axios //Get details
                .all(
                  responses.map((itemStem) =>
                    icdAxios.get(
                      itemStem.data.stemId.replace("http:", "https:"),
                      getHeader(token)
                    )
                  )
                )
                .then(
                  axios.spread((...responsesStems) => {
                    let str = codesArray + " - ";
                    responsesStems.forEach(
                      (resItem, index) =>
                        (str =
                          str +
                          (index === 0
                            ? resItem.data.title["@value"]
                            : ", [" + resItem.data.title["@value"] + "]"))
                    );
                    onDecoded(str);
                  })
                )
                .catch(() => {
                  if (onError) {
                    onError();
                  }
                });
            })
          )
          .catch(() => {
            if (onError) {
              onError();
            }
          });
      }
    })
    .catch(() => {
      if (onError) {
        onError();
      }
    });
};

import PropTypes from "prop-types";

import dermusLogo from "./logo.svg";
import styles from "./Logo.module.css";

const logo = (props) => (
  <div className={styles.Logo} style={{ height: props.height }}>
    <img src={dermusLogo} alt="Dermus" />
  </div>
);

logo.propTypes = {
  height: PropTypes.number,
};

export default logo;
//export default React.memo(logo);

import Spinner from "../Spinner/Spinner";
import Button from "./Button";
import { Fragment } from "react";

import saveAs from "file-saver";

const DownloadButton = ({ pending, title, data, fileName }) => {
  const onClick = () => {
    if (data) {
      saveAs(data, fileName);
    }
  };
  return (
    <Fragment>
      {pending ? <Spinner /> : <Button title={title} onClick={onClick} disabled={!data} />}
    </Fragment>
  );
};
export default DownloadButton;

import { useRef, useEffect, useState } from "react";
import Chart from "chart.js/auto";

const Histogram = ({ width, height, type, dataset }) => {
  const ref = useRef();
  const [chart, setChart] = useState(null);

  useEffect(() => {
    if (chart) {
      chart.destroy();
    }
    const ctx = ref.current.getContext("2d");
    const myChart = new Chart(ctx, {
      type: type ? type : "bar",
      data: {
        datasets: [dataset],
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
    setChart(myChart);
    return () => {
      myChart.destroy();
    }; // eslint-disable-next-line
  }, [dataset.data]);
  return (
    <div style={{ width, height }}>
      <canvas ref={ref} />
    </div>
  );
};
export default Histogram;

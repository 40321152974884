import { useState, useCallback, useRef, useEffect } from "react";
import ReactTooltip from "react-tooltip";

import { checkValidity } from "../utility";
import styles from "./UserInput.module.css";

/**
 * User input
 * @param {Object} props {submitted: is submitted or not, value: string, placeholder: string, validation: object, type: string, onEnter: on enter (function)}
 */
const useCheckbox = (props) => {
  //Disabled
  const [disabled, setDisabled] = useState(props.disabled);
  //Update lesion note state
  const [state, setState] = useState({
    value: props.value ? props.value : "",
    valid: props.value ? true : false,
    touched: props.value ? true : false,
  });
  //Ref
  const inputRef = useRef();
  //Configuration
  const [config] = useState({
    validation: props.validation ? props.validation : { checked: true },
    label: props.label ? props.label : null,
    tip: props.tip ? props.tip : "",
  });
  useEffect(() => {
    ReactTooltip.rebuild();
  });
  //Style
  const inputClasses = [disabled ? styles.CheckmarkDisabled : styles.Checkmark];
  if (!state.valid && (state.touched || props.submitted)) {
    inputClasses.push(styles.InvalidChecked);
  }
  //Input handler
  const inputChangedHandler = useCallback(() => {
    setState((prevState) => ({
      value: !prevState.value,
      valid: checkValidity(!prevState.value, config.validation),
      touched: true,
    }));
  }, [config]);
  return [
    <div className={styles.Input}>
      <label className={styles.Label}>
        {props.label}
        <input
          className={styles.CheckBox}
          type="checkbox"
          checked={state.value}
          onChange={() => {}}
        />
        <span
          onClick={disabled ? () => {} : inputChangedHandler}
          className={inputClasses.join(" ")}
          data-tip={disabled ? config.tip : ""}
          data-cy="checkbox-check"
        ></span>
      </label>
    </div>,
    state,
    setState,
    inputRef,
    setDisabled,
  ];
};

export default useCheckbox;

import { Fragment, useEffect } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import * as links from "../../dermusbase/Constants/Links";
import Login from "../../dermusbase/UI/Authentication/Login";
import ForgotPassword from "../../dermusbase/UI/Authentication/ForgotPassword";
import * as authAPI from "../../dermusbase/API/authenticationAPI";
import { STALE_SERVE } from "../../dermusbase/Constants/Message";
import Snackbar from "../../dermusbase/UI/Snackbar/Snackbar";
import ReactTooltip from "react-tooltip";
import BaseStat from "./BaseStat";
import { BASE_STAT_URL } from "../../Constants/Links";

const AdminHome = () => {
  //Redux dispatch
  const dispatch = useDispatch();
  //Stale cache
  const stale = useSelector((state) => state.auth.stale);
  //Cognito session
  const session = useSelector((state) => state.auth.session);
  //Location hook
  const location = useLocation();
  //Chcek session is saved or not
  useEffect(() => {
    authAPI.checkUserValidity(dispatch);
  }, [dispatch]);

  //Loged in
  if (session) {
    return (
      <Fragment>
        <ReactTooltip />
        <Snackbar msg={STALE_SERVE} show={stale} />
        <Switch>
          <Route path={BASE_STAT_URL} exact component={BaseStat} />
          <Redirect
            from={links.LOGIN_URL}
            to={
              location.pathname.substring(links.LOGIN_URL.length) +
              location.search +
              location.hash
            }
          />
          <Redirect to={BASE_STAT_URL} />
        </Switch>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Switch>
          <Route path={links.FORGOT_PSW_URL} exact component={ForgotPassword} />
          <Route
            path={links.LOGIN_URL}
            component={(props) => <Login {...props} onlyLogin={true} />}
          />
          <Redirect
            from={BASE_STAT_URL}
            to={links.LOGIN_URL + location.pathname}
          />
          <Redirect to={links.LOGIN_URL} />
        </Switch>
      </Fragment>
    );
  }
};

export default AdminHome;

import axios from "axios";
import { responseHandler, requestHandler, errorHandler } from "./axiosUtil";

export const BASEURL = `https://${
  process.env.REACT_APP_ENVIRONMENT
    ? process.env.REACT_APP_ENVIRONMENT.localeCompare("prod") === 0
      ? "prod"
      : "dev"
    : "dev"
}.api.dermusvision.com/v1`;
const whiteList = [];
const MAX_AGE = 0; //5 * 60 * 1000; //millisec  -- 5 mins
const MAX_STALE = 0; //24 * 60 * 60 * 1000; //millisec  -- 1 day

const instance = axios.create({
  baseURL: BASEURL,
});

instance.interceptors.request.use(
  (request) => requestHandler(request, MAX_AGE, MAX_STALE),
  (error) => errorHandler(error, MAX_AGE, MAX_STALE)
);
instance.interceptors.response.use(
  (response) => responseHandler(response, whiteList),
  (error) => errorHandler(error, MAX_AGE, MAX_STALE)
);

export default instance;

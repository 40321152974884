import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import GeneralModal from "../Modal/GeneralModal";
import { resetPassword, confirmPassword } from "../../API/authenticationAPI";
import {
  CONFIRM_NEW_PASSWORD,
  VERIFICATION_CODE,
  NEW_PASSWORD,
  RESET_PSW,
} from "../../Constants/Message";
import { clearAuthStateActionCreator } from "../../store/actionCreators/authenticationActionCreator";
import { LOGIN_URL } from "../../Constants/Links";
import ErrorMessage from "../Divs/ErrorMessage/ErrorMessage";
import CenterForm from "../Divs/CenterForm/CenterForm";
import Button from "../Button/Button";
import useUserInput, {
  EMAIL_TYPE,
  PSW_TYPE,
  NUM_TYPE,
} from "../CustomInput/useUserInput";

const ForgotPassword = () => {
  //Histroy data
  const history = useHistory();
  //Email Input view
  const [emailComponent, email] = useUserInput(EMAIL_TYPE);
  //Redux dispatch
  const dispatch = useDispatch();
  //Verification code Input view
  const [verificationCodeComponent, verCode] = useUserInput({
    ...NUM_TYPE,
    placeholder: VERIFICATION_CODE,
  });
  //Password  Input view
  const [pswComponent, psw] = useUserInput({
    ...PSW_TYPE,
    placeholder: NEW_PASSWORD,
  });
  //Password confimrmation Input view
  const [pswConfComponent, pswConf] = useUserInput({
    ...PSW_TYPE,
    placeholder: CONFIRM_NEW_PASSWORD,
  });
  //Auth pending state
  const pending = useSelector((state) => state.auth.pending);
  //Auth error state
  const error = useSelector((state) => state.auth.error);
  //Auth info state
  const info = useSelector((state) => state.auth.info);

  //Clear auth state end
  useEffect(() => {
    return () => dispatch(clearAuthStateActionCreator());
  }, [dispatch]);

  //Submit handler
  const submitHandler = (event) => {
    event.preventDefault();
    resetPassword(email, dispatch);
  };

  return (
    <Fragment>
      <CenterForm
        title={RESET_PSW}
        error={error && !info ? error : null}
        pending={pending && !info}
      >
        <form onSubmit={submitHandler}>
          {emailComponent}
          <Button title={RESET_PSW} />
        </form>
      </CenterForm>
      <GeneralModal
        title={RESET_PSW}
        onCancel={() => dispatch(clearAuthStateActionCreator())}
        onConfirm={() =>
          confirmPassword(email, verCode, psw, pswConf, dispatch, () =>
            history.replace(LOGIN_URL)
          )
        }
        pending={pending}
        show={info ? true : false}
      >
        <ErrorMessage error={error} />
        {verificationCodeComponent}
        {pswComponent}
        {pswConfComponent}
      </GeneralModal>
    </Fragment>
  );
};

export default ForgotPassword;

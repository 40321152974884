import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import styles from "./Login.module.css";
import { login } from "../../API/authenticationAPI";
import { SIGN_UP_URL, FORGOT_PSW_URL } from "../../Constants/Links";
import {
  FORGOT_PSW,
  SIGN_UP,
  SIGN_IN,
  LOGIN_ALT,
} from "../../Constants/Message";
import { clearAuthStateActionCreator } from "../../store/actionCreators/authenticationActionCreator";
import useUserInput, {
  EMAIL_TYPE,
  PSW_TYPE,
} from "../CustomInput/useUserInput";
import ErrorMessage from "../Divs/ErrorMessage/ErrorMessage";
import Button from "../Button/Button";
import CenterForm from "../Divs/CenterForm/CenterForm";

import loginpic from "./../../assets/images/login.png";
import { getEnv } from "../../utility/utility";
import Spinner from "../Spinner/Spinner";

const Login = (props) => {
  //End
  const [isDev] = useState(getEnv());
  //submitted or not
  const [submitted, setSubmitted] = useState(false);
  //Email input
  const [emailComponent, email] = useUserInput({ ...EMAIL_TYPE, submitted });
  //Pasw input
  const [pswComponent, psw, setPsw] = useUserInput({ ...PSW_TYPE, submitted });
  //Redux dispatch
  const dispatch = useDispatch();
  //Auth status pending
  const pending = useSelector((state) => state.auth.pending);
  //Auth status error
  const error = useSelector((state) => state.auth.error);

  //Clear auth on leave
  useEffect(() => {
    return () => dispatch(clearAuthStateActionCreator());
  }, [dispatch]);

  //Clear psw on error
  useEffect(() => {
    if (error) {
      setPsw({ value: "", valid: false, touched: false });
    }
  }, [error, setPsw]);

  //On login handler
  const submitHandler = (event) => {
    event.preventDefault();
    setSubmitted(true);
    login(email, psw, dispatch);
  };

  return (
    <div className={!isDev ? styles.Background : styles.Background_With_Footer}>
      <CenterForm>
        <div className={styles.LoginContainer}>
          <div className={styles.LoginImg}>
            <img src={loginpic} alt={LOGIN_ALT} />
          </div>
          <form className={styles.LoginForm} onSubmit={submitHandler}>
            <div className={styles.Title}>{SIGN_IN}</div>
            {pending ? (
              <Spinner />
            ) : (
              <Fragment>
                <ErrorMessage error={error} />
                {emailComponent}
                {pswComponent}
                <Button title={SIGN_IN} />
                {!!!props.onlyLogin && (
                  <div className={styles.Links}>
                    <NavLink to={SIGN_UP_URL} data-cy="sign-up">
                      {SIGN_UP}
                    </NavLink>
                    <NavLink to={FORGOT_PSW_URL} data-cy="forgot-psw">
                      {FORGOT_PSW}
                    </NavLink>
                  </div>
                )}
              </Fragment>
            )}
          </form>
        </div>
      </CenterForm>
    </div>
  );
};

/*onSubmit={this.submitHandler}*/
export default Login;

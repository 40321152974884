import PropTypes from "prop-types";

import styles from "./DrawerToggle.module.css";

/**
 * drawerToggle
 * @param {Object} props  {onClick: oc click function(function)}
 */
const drawerToggle = (props) => {
  return (
    <div
      className={styles.DrawerToggle}
      onClick={props.onClick}
      data-cy="drawer-toggle"
    >
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
drawerToggle.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default drawerToggle;

import { Fragment } from "react";

//Login
export const INVALID_PSW_OR_E_MAIL = "Please add valid e-mail and password";
export const ENTER_YOUR_PSW = ", please enter your password";
export const SIGN_IN = "Sign in";
export const WELCOME = "Welcome";

//
export const ANNOTATION_FLAG = "**ANNOTATION**";
export const ANNOTATION_US_FLAG = "**ANNOT_US**";
export const SETTINGS = "Settings";
export const LOGOUT = "Logout";

export const ERROR_OCCURRED = "Error Occurred";
export const OK = "OK";
export const CANCEL = "Cancel";

export const ARE_YOU_SURE = "Are you sure you?";
export const SAVING = "Saving...";

export const ARE_YOU_SURE_DELETE_PROFILE =
  "Are you sure want to delete your profile?";

export const YEARS_AGO = "years ago";
export const FEW_YEARS_AGO = "few years ago";
export const ONE_YEAR_AGO = "one year ago";
export const MONTHS_AGO = "months ago";
export const FEW_MONTHS_AGO = "few months ago";
export const A_MONTH_AGO = "a month ago";
export const FEW_WEEKS_AGO = "few week ago";
export const A_WEEK_AGO = "a week ago";
export const FEW_DAYS_AGO = "few days ago";
export const A_DAY_AGO = "a day ago";
export const FEW_HOURS_AGO = " few hours ago";
export const A_HOUR_AGO = "a hour ago";
export const FEW_MINUTES_AGO = "few minutes ago";
export const RECENTLY = "recently";
export const CHOOSE = "choose";
export const CHOOSE_SPEC = "Choose specialization...";
export const LOGIN_ALT = "Login image";
export const NO_RECORD_YET = "No record yet";
export const NO_LESION_YET = "No lesion yet";
export const ONLY_OPT = "**ONLY_OPTICAL**";
export const SPECS = [
  { value: "dermatologist", displayValue: "Dermatologist" },
  {
    value: "general_practitioner",
    displayValue: "General practitioner",
  },
  { value: "surgeon", displayValue: "Surgeon" },
  { value: "other", displayValue: "Other" },
];
export const TEST_DEV_MODE_MSG =
  "WARNING: EVALUATION MODE ONLY. USE ONLY BY PERSONNEL APPROVED BY DERMUS KFT.";

//Placeholders
export const EMAIL = "E-mail";
export const CONFIRM_EMAIL = "Confirm e-mail";
export const CONFIRM_NEW_PASSWORD = "Confirm new password";
export const NEW_PASSWORD = "New password";
export const CONFIRM_PASSWORD = "Confirm password";
export const PASSWORD = "Password";
export const Name = "Name";
export const AFFILIATION = "Affiliation";
export const USER_NAME = "Name";
export const MPRN = "Medical Practitioner Reg. Num. (if applicable)";
export const COMPANY_NAME = "Company Name";
export const COMPANY_ADDRESS = "Company Address";
export const ADDRESS = "Address";
export const COMPANY_VAT = "Company VAT Number";
export const BILLING_INFO = "Billing Details";
export const COMPANY = "Company";

//Encrypt-Decrypt
export const CANNOT_DECRYPT_DATA = "Cannot decrypt data";

export const ADD_VALID_USER_NAME = "Please add valid name";
export const ADD_VALID_AFF = "Please add valid affiliation";
export const ADD_VALID_SPEC = "Please choose a specialization";
export const ADD_VALID_PSW = "Please add valid password";
export const ADD_VALID_EMAIL = "Please add valid e-mail";
export const PSWS_NOT_SAME = "The two passwords are not the same";
export const EMAILS_NOT_SAME = "The two emails are not the same";
export const NO_ADMIN_RIGHTS = "User has no admin rights";

export const ADD_VALID_COMPANY_NAME = "Please add valid company name";
export const ADD_VALID_COMPANY_VAT = "Please add valid company VAT number";
export const ADD_VALID_COMPANY_ADDRESS = "Please add valid company address";
export const ADD_VALID_ADDRESS = "Please add valid address";

export const SUCCESS = "Succes";
export const SIGN_UP_SUCCESS_CONFIRM =
  "To finish the registration process, please verify your e-mail by click on the link in the e-mail sent your address.";
export const CONFIRM_REGISTRATION =
  "By pressing Confirm, I confirm that the details provided above are correct and I request to register with SkinAid under the terms already approved by me.";
export const CONFIRM = "Confirm";
export const CONFIRM_SIGN_UP = "Confirm sign up";
export const BACK = "Back";
export const NOT_AGREE_PRIVACY_POLICY =
  "Please read and agree with the Privacy Policy";
export const NOT_AGREE_TERMS_AND_CONDITION =
  "Please read and agree with the Terms and Conditions";
export const PRIVACY_POLICY = "Privacy Policy";
export const TERMS_AND_CONDITION = "Terms and Conditions";
export const READ_AND_UNDERSTOOT = "I have read and understood the ";
export const LATEST = "Latest";
export const PREV_VERSIONS = "Previous versions";
export const READ_FIRST_TERMS_AND_CONDITION =
  "Please read the Terms and Conditionss";
export const READ_FIRST_PRIVACY_POLICY = "Please read the Privacy Policy";
export const ABOUT_DERMUS = "About Dermus";
export const ABOUT = "About";
export const READ_USER_MANUAL = "Read User Manual";
export const USER_MANUAL = "User manual";
export const SIGN_UP = "Sign up";
export const KNOW_MORE = (props) => {
  return (
    <Fragment>
      If you want to know more about us, visit {props.children}.
    </Fragment>
  );
};

export const RELEASE_NOTES_TITLE = "Release notes";
export const NOTES = "Notes";
export const RELEASE_DATE = "Release date";
export const USER_PROFILE = "Profile";
export const SPECIALIZATION = "Specialization";
export const MPRN_FULL = "Medical Practitioner Registration Number";
export const YES = "Yes";
export const NO = "No";
export const DELETE_PROFILE = "Delete profile";
export const MODIFY_PROFILE = "Modify profile";
export const SAVE = "Save";
export const UPDATE_PSW = "Change password";
export const UPDATE_EMAIL = "Change e-mail";
export const NEW_EMAIL = "New e-mail";
export const NEW_PSW = "New password";
export const ACT_PSW = "Actual password";
export const THIS_EMAIL_USED_ALREADY = "This e-mail is used already.";

//Forgot psw
export const FORGOT_PSW = "Forgot password?";
export const ADD_VALID_VERIFICATION_CODE = "Please add valid verification code";
export const VERIFICATION_CODE = "Verification code (sent via e-mail)";
export const RESET_PSW = "Reset password";

export const STALE_SERVE = "Error, request served from cache.";

export const TESTING_ONLY = "TESTING ONLY";
export const ADMIN = "ADMIN";

export const EXOPRT_ALL = "Export all";

import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import style from "./DateRangePicker.module.css";

export const CALENDAR_WIDTH = 375;
const CustomDateRangePicker = ({ width, state, setState }) => {
  return (
    <div className={style.Container} width={width}>
      <DateRange
        editableDateInputs={true}
        onChange={(item) => {
          setState([item.selection]);
        }}
        moveRangeOnFirstSelection={false}
        ranges={state}
        months={width < 2 * CALENDAR_WIDTH ? 1 : 2}
        direction="horizontal"
      />
    </div>
  );
};
export default CustomDateRangePicker;

import PropTypes from "prop-types";

import styles from "./TitleWithBackground.module.css";
/**
 * title with backfroung
 * @param {Object} props {title: string}
 */
const titleWithBackground = (props) => (
  <h2 className={styles.Title} data-cy="title">
    {props.title}
  </h2>
);
titleWithBackground.propTypes = {
  title: PropTypes.string,
};
export default titleWithBackground;

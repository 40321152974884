// CLOUD
export const ALL_PATIENTS_EP = "/patient";
export const ADD_PETIENT_EP = "/patient";
export const UPDATE_PATIENT_EP = "/patient";
export const ALL_LESION_EP_PRE = "/patient";
export const ALL_LESION_EP_POST = "/lesion";
export const ALL_RECORD_EP_PRE = "/lesion";
export const ALL_RECORD_EP_POST = "/record";
export const RECORD_EP = "/record";
export const REPORT_EP = "/report";
export const SEND_REPORT_EP = "/send-report";
export const MAKE_EP = "/make";
export const IMAGE_EP = "/image";
export const LESION_UPDATE_EP = "/lesion";
export const ADD_RECORD_NOTE_EP_PRE = "/record";
export const ADD_RECORD_NOTE_EP_PRO = "/note";
export const ADD_LESION_EP = "/lesion";
export const RECORD_THUMBNAIL_AND_DICOM_EP = "/dicom/record";
export const RECORD_ANNOTATION_EP = "/annotation";
export const ICD_TOKEN_EP = "/ICDToken";
export const NOTE_TAGS = "/note-tags";
export const SIGN_UP_EP = "/signup";
export const LOGOUT_EP = "/logout";
export const LOGIN_EP = "/login";
export const USER_EP = "/user";
export const USERS_EP = "/users";
export const FORGOT_PSW_EP = "/forgot-password";
export const CHANGE_PSW_EP = "/user/modify-password";
export const CHANGE_EMAIL_EP = "/user/modify-email";
export const ADMIN_RECORD_STATS_EP = "/admin/all-record-data";
export const ADMIN_RECORD_STATS_FILTERED_EP = "/admin/all-filtered-record-data";
export const ADMIN_RECORD_STATS_ASYNC_EP = "/admin/async-all-record-data";
export const ADMIN_MEASUREMENTS_EP = "/admin/measurement-data";
export const ADMIN_RECIRD_STAT_FILE_EP = "/admin/all-record-file"


// ICD
export const ICD_EP = "https://id.who.int/icd/release/11/2020-09/mms/codeinfo/";

// ALGO CLOUD
export const ALGO_SCALE_DETECTION_EP = "/optical-scale-transform";
export const ALGO_DEPTH_INFO_EP = "/opto-fuse";
export const ALGO_US_SEG_EP = "/us-segmentation";
export const ALGO_DATA_US_SEG_EP = "/data-us-segmentation";
export const ALGO_US_MEASUREMENTS = "/us-measurements";

class Action {
  /**
   * Construtor
   * @param {String} type type of action
   * @param {Object} payload payload data
   */
  constructor(type, payload) {
    this.type = type;
    this.payload = payload;
  }
}

export default Action;

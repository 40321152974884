import styles from "./SizeChangedText.module.css";
/**
 * Info msg
 * @param {Object} props {info: {message: string}}
 */
const SizeChangedText = (props) => (
  <div className={styles.Children}>{props.children}</div>
);

export default SizeChangedText;

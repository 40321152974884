export const HOME_URL = "/";
export const LOGIN_URL = "/login";
export const FORGOT_PSW_URL = "/resetpassword";
export const SIGN_UP_URL = "/signup";
export const PRIVACY_POLICY_HASH = "#Privacy-Policy";
export const TERMS_AND_CONDITION_HASH = "#Terms-and-Condition";
export const USER_MANUAL_HASH = "#User-Manual";
export const ABOUT_URL = "/about";
export const SETTINGS_URL = "/settings";

export const DERMUS_URL = "https://www.dermusvision.com/";
export const SKINAID_URL = "https://www.skinaid.app/";

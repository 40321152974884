import { storage, removeStorageItem } from "./localStorageAPI";
import { GET } from "./useHttp";

const SEPARATOR = "//**//";

/**
 * Store data in cache
 * @param {String} key key
 * @param {String} value value
 */
export const store = (key, value) => {
  const finalValue = `${value}${SEPARATOR}${Date.now().toString()}`;
  storage.setItem(key, finalValue);
};

/**
 * Response handler
 * @param {Response} response response
 */
export const responseHandler = (response, whiteList) => {
  if (
    GET.toLowerCase().localeCompare(response.config.method.toLowerCase()) === 0
  ) {
    if (
      response.config &&
      response.config.url &&
      !whiteList.includes(response.config.url.split("/")[1])
    ) {
      // console.log("storing in cache");
      store(response.config.url, JSON.stringify(response.data));
    }
  }
  return response;
};

/**
 * Request handler
 * @param {Request} request request
 */
export const requestHandler = (request, maxAge, maxStale) => {
  if (GET.toLowerCase().localeCompare(request.method.toLowerCase()) === 0) {
    const checkIsValidResponse = isValid(request.url || "", maxAge, maxStale);
    if (checkIsValidResponse.isValid) {
      // console.log("serving cached data");
      request.headers.cached = true;
      request.data = JSON.parse(checkIsValidResponse.value || "{}");
      return Promise.reject(request);
    }
  }
  return request;
};

/**
 * Error handler
 * @param {Error} error error
 */
export const errorHandler = (error, maxAge, maxStale) => {
  if (error.headers && error.headers.cached === true) {
    // console.log("got cached data in response, serving it directly");
    return Promise.resolve(error);
  }
  const checkIsValidResponse = isValid(
    error.config ? error.config.url || "" : "",
    maxAge,
    maxStale
  );
  if (checkIsValidResponse.isValidOnError) {
    // console.log("serving stale cached data");
    const request = {
      data: JSON.parse(checkIsValidResponse.value || "{}"),
      staleCache: true,
    };
    return Promise.resolve(request);
  }

  return Promise.reject(error);
};

/**
 * Check valud of the key is valid
 * @param {String} key key
 */
const isValid = (key, maxAge, maxStale) => {
  const value = storage.getItem(key);
  if (value === null) {
    return {
      isValid: false,
      isValidOnError: false,
    };
  }
  const values = value.split(SEPARATOR);
  const timestamp = Number(values[1]);
  if (Number.isNaN(timestamp)) {
    return {
      isValid: false,
      isValidOnError: false,
    };
  }
  const date = new Date(timestamp);
  if (date.toString() === "Invalid Date") {
    return {
      isValid: false,
      isValidOnError: false,
    };
  }
  const duration = Date.now() - date.getTime();
  if (duration < maxAge) {
    return {
      isValid: true,
      value: values[0],
    };
  }
  if (duration < maxStale) {
    return {
      isValid: false,
      isValidOnError: true,
      value: values[0],
    };
  }

  removeStorageItem(key);
  return {
    isValid: false,
    isValidOnError: false,
  };
};

import { Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//import PatientManagment from "./Components/PatientManagement/PatientManagment";
import AdminHome from "./Components/Admin/AdminHome";
import Layout, { isMobilMode } from "./dermusbase/UI/Container/Layout";
import useEventListener from "./dermusbase/hooks/useEventListener";
import { USER_SIGNED_IN_LOCAL_STORAGE } from "./dermusbase/Constants/Constants";
import { checkUserValidity } from "./dermusbase/API/authenticationAPI";
import NavigationItems from "./Components/Navigation/NavigationItems";
import useWindowSize from "./dermusbase/hooks/useWindowSize";
import packageJson from "../package.json";
const App = () => {
  //Cognito session
  const session = useSelector((state) => state.auth.session);
  //Loggidin user
  const userLoggedIn = useSelector((state) => state.auth.signedInUser);
  //win size
  const size = useWindowSize();
  //Dispatch
  const dispatch = useDispatch();

  useEventListener("storage", (event) => {
    if (!event.key) {
      checkUserValidity(dispatch);
      return;
    }
    if (event.key.localeCompare(USER_SIGNED_IN_LOCAL_STORAGE) === 0) {
      if (
        !localStorage.getItem(USER_SIGNED_IN_LOCAL_STORAGE) ||
        !userLoggedIn
      ) {
        checkUserValidity(dispatch);
      }
    }
  });

  const navItems = (
    <NavigationItems
      isAuthenticated={session ? true : false}
      isMobileMode={isMobilMode(size.width)}
    />
  );

  return (
    <Switch>
      <Layout
        isAuthenticated={session ? true : false}
        navItems={navItems}
        appVersion={packageJson.version}
      >
        <Route path="/" component={AdminHome} />
      </Layout>
    </Switch>
  );
};

export default App;

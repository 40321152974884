import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import styles from "./NavigationItems.module.css";
import NavigationItem from "../../dermusbase/UI/Navigation/NavigationItem/NavigationItem";
import Icon from "../../dermusbase/UI/Icon/Icon";

import * as links from "../../dermusbase/Constants/Links";
import * as titles from "../../dermusbase/Constants/Titles";

import { logout } from "../../dermusbase/API/authenticationAPI";

import { LOGOUT } from "../../dermusbase/Constants/Message";
import logoutLogo from "../../dermusbase/assets/icon/logout.svg";

/**
 *
 * @param {Object} props {isAuthenticated: auth or not (boolean)}
 */
const NavigationItems = (props) => {
  //Dispatch
  const dispatch = useDispatch();
  //Cognito session
  const session = useSelector((state) => state.auth.session);
  return (
    <ul className={styles.NavigationItems}>
      {props.isAuthenticated ? (
        <Fragment>
          {props.isMobileMode ? (
            <Fragment>
              <hr className={styles.Line} />
              <NavigationItem
                link={links.LOGIN_URL}
                exact
                onClick={() => logout(dispatch, session)}
              >
                {titles.LOGOUT}
              </NavigationItem>
            </Fragment>
          ) : (
            <Fragment>
              <Icon
                datacy="icon_logo"
                hint={LOGOUT}
                imgLink={links.LOGIN_URL}
                iconImage={logoutLogo}
                onClick={() => logout(dispatch, session)}
              />
            </Fragment>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <NavigationItem link={links.LOGIN_URL}>{titles.LOGIN}</NavigationItem>
        </Fragment>
      )}
    </ul>
  );
};

NavigationItems.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isMobileMode: PropTypes.bool.isRequired,
};

export default NavigationItems;
//export default React.memo(navigationItems);
